import { Link, withPrefix } from "gatsby";
import React from "react";

interface Props {
  /** describe an image inside a card */
  mediaAlt: string | undefined;

  /** display an image inside a card */
  mediaSrc: string | undefined;

  /** Optional card title */
  title?: string | null;

  /** Optional card subtitle */
  subtitle?: string | null;

  /** The URL you want to link to */
  url: string;
}

const defaultProps: Props = {
  mediaAlt: undefined,
  mediaSrc: undefined,
  title: null,
  subtitle: null,
  url: "",
};

const CardArticle: React.FC<Props> = (props) => {
  const { mediaAlt, mediaSrc, title, subtitle, url } = props;

  return (
    <article>
      <Link
        to={url}
        className="uk-display-block uk-card uk-card-default uk-card-hover uk-link-toggle"
      >
        {mediaSrc && (
          <div className="uk-card-media-top">
            <div className="uk-cover-container">
              <canvas width={382} height={287} />
              <img src={withPrefix(mediaSrc)} alt={mediaAlt} uk-cover="" />
            </div>
          </div>
        )}
        {(title || subtitle) && (
          <div className="uk-card-header">
            {title && (
              <h1 className="uk-card-title uk-margin-remove-bottom">
                <span className="uk-link-heading">{title}</span>
              </h1>
            )}
            {subtitle && (
              <p className="uk-margin-remove-top uk-text-emphasis c-text-xsmall">
                {subtitle}
              </p>
            )}
          </div>
        )}
      </Link>
    </article>
  );
};

export default CardArticle;

CardArticle.defaultProps = defaultProps;
